<template>
  <div class="page">
    <p>
      如果您的路由器同时支持2.4Ghz和5Ghz两个的Wi-Fi，但是在手机的Wi-Fi搜索页面中却只能搜到一个Wi-Fi名称，请参考以下操作步骤:
    </p>
    <p>1、进入路由器的“无线设置(Wireless)”页面(进入方法通常在路由器背面的标签上);</p>
    <p>2、找到2.4Ghz的设置页面，将Wi-Fi名称(SSID)更改为“xxx-2.4G”，保存设置;</p>
    <p>3、找到5Ghz的设置页面，将Wi-Fi名称(SSID)更改为“xxx-5G”，保存设置;</p>
    <p>4、更名完成后，即可在手机的Wi-Fi搜索页面中分别找到“xxx-2.4G”和“xxx-5G”两个Wi-Fi名称了;</p>
    <p>注:由于Wi-Fi名称已变更，所有连接该无线路由器的设备都需要重新连网，如果夫对密码发生变更则依然为原密码。</p>
    <p>以下为一些常用路由器品牌的具体设置流程。</p>
    <p><router-link to="/documents/NetgearRouter">网件路由器设置</router-link></p>
    <p><router-link to="/documents/AsusRouter">华硕路由器设置</router-link></p>
    <p><router-link to="/documents/DLinkRouter">D-Link路由器设置</router-link></p>
    <p><router-link to="/documents/TPLinkRouter">TP-Link路由器设置</router-link></p>
  </div>
</template>

<script>
export default {
  name: '',
  components: {},

  props: {},

  data() {
    return {}
  },

  filters: {},
  computed: {},
  watch: {},

  created() {},

  methods: {}
}
</script>

<style scoped lang="scss">
.page {
  padding: 34px 40px;
  font-size: 32px;
  font-family: PingFang-SC-Medium, PingFang-SC;
  font-weight: 500;
  color: #333333;

  p {
    margin-bottom: 26px;
  }
}
</style>
